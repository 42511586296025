import React from "react";
import { isoOrderID } from "tsi-common-react/src/models/nominals";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";

dynamicPlaceComponent(
    '[data-place-react="order-cancel-button"]',
    async (elem) => {
        const { OrderCancelButton } = await import(
            "tsi-common-react/src/apps/orders/components/OrderCancelButton"
        );
        const orderID = parseInt(elem.dataset.order || "", 10);
        if (!orderID) {
            return null;
        }
        return <OrderCancelButton orderID={isoOrderID.wrap(orderID)} />;
    },
).catch(console.error);
