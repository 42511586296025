import React from "react";
import { Provider } from "react-redux";
import * as checkoutAPI from "tsi-common-react/src/api/checkout";
import { getChatConnector } from "tsi-common-react/src/apps/chat/index";
import { registerCheckoutCascades } from "tsi-common-react/src/apps/checkout/cascades-checkout";
import { registerCommonCascades } from "tsi-common-react/src/apps/checkout/cascades-main";
import {
    BasketLineVariant,
    Step,
} from "tsi-common-react/src/apps/checkout/constants";
import { Dispatchers as CheckoutDispatchers } from "tsi-common-react/src/apps/checkout/dispatchers";
import { Loaders } from "tsi-common-react/src/apps/checkout/loaders";
import {
    openApplyModal,
    openPreQualModal,
} from "tsi-common-react/src/apps/financing/reducers";
import * as signals from "tsi-common-react/src/apps/signals";
import {
    FinancingCardType,
    FinancingModalTriggerTheme,
} from "tsi-common-react/src/constants";
import { onReadyStateComplete } from "tsi-common-react/src/utils/events";
import { strToBool } from "tsi-common-react/src/utils/format";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import {
    getPageSetting,
    isPageInsideIFrame as isPageInsideIFrameCheck,
} from "tsi-common-react/src/utils/settings";
import { urls } from "tsi-common-react/src/utils/urls";

import { persistor, rehydratingStore, store } from "../store";
import { getNavHeight, getPreHeaderHeight } from "../ui/utility";

// ============================================================================
// Snap Engage Pop-up Chat Integrations
// ============================================================================
signals.financing.onApproval.on(() => {
    if (strToBool(getPageSetting("chat-enabled"))) {
        getChatConnector().triggerCampaign(
            getPageSetting("chat-wf-campaign-key"),
        );
    }
});

// ============================================================================
// Setup Redux Store / Redux Event Dispatchers
// ============================================================================
export const dispatchers = new CheckoutDispatchers(store.dispatch);
export const loaders = new Loaders(dispatchers);

// ============================================================================
// Render checkout app components
// ============================================================================
/* eslint-disable-next-line no-async-promise-executor */
const componentsRendering = rehydratingStore.then(() => {
    // Register store cascades
    registerCommonCascades(store);

    // Render basket menu icons
    dynamicPlaceComponent('[data-place-react="basket-menu-icon"]', async () => {
        const { BasketMenuIcon } = await import(
            "tsi-common-react/src/apps/checkout/containers/BasketMenuIcon"
        );
        const { FinancingModalTriggerMinicart } = await import(
            "./components/FinancingModalTriggerMinicart"
        );
        const enableBasketPrequalSDK = strToBool(
            getPageSetting("show-prequal-cart"),
        );
        const basketURL = urls.pageURL("basket-summary");
        const checkoutURL = urls.pageURL("checkout-index");
        const isPageInsideIFrame = isPageInsideIFrameCheck();
        return (
            <Provider store={store}>
                <BasketMenuIcon
                    basketURL={basketURL}
                    checkoutURL={checkoutURL}
                    isPageInsideIFrame={isPageInsideIFrame}
                    basketLineVariant={BasketLineVariant.MINIMAL_ENHANCED}
                    addPopperArrow={true}
                    buildPromoComponents={(closeModal) => {
                        if (!enableBasketPrequalSDK) {
                            return null;
                        }
                        return (
                            <Provider store={store}>
                                <FinancingModalTriggerMinicart
                                    closeModal={closeModal}
                                />
                            </Provider>
                        );
                    }}
                    getPreHeaderHeight={getPreHeaderHeight()}
                />
            </Provider>
        );
    }).catch(console.error);

    // Render Basket Application
    dynamicPlaceComponent("#basket-app", async () => {
        const { Basket } = await import(
            "tsi-common-react/src/apps/checkout/containers/Basket"
        );
        const { BasketSidebarContent } = await import(
            "./components/BasketSidebarContent"
        );
        const { BasketFinePrint } = await import(
            "./components/BasketFinePrint"
        );
        const { getLineProductUpsell } = await import(
            "./components/BasketLineSuggestions"
        );

        return (
            <Provider store={store}>
                <Basket
                    getLineProductUpsell={getLineProductUpsell}
                    showValueProps={true}
                    getExtraSummaryContent={() => {
                        return null;
                    }}
                    getExtraSidebarContent={(data, isMobile) => {
                        const cartID = data.basket
                            ? data.basket.encoded_basket_id
                            : "";
                        return (
                            <BasketSidebarContent
                                plans={data.financing_plans}
                                isMobile={isMobile}
                                cartID={cartID}
                            />
                        );
                    }}
                    getExtraMainColumnContent={(data, isMobile) => {
                        if (isMobile) {
                            return null;
                        }
                        return <BasketFinePrint plans={data.financing_plans} />;
                    }}
                    getNavHeight={getNavHeight()}
                />
            </Provider>
        );
    }).catch(console.error);

    // Render Checkout Application
    dynamicPlaceComponent("#checkout-app", async () => {
        registerCheckoutCascades(store);
        const { Checkout } = await import(
            "tsi-common-react/src/apps/checkout/containers/Checkout"
        );
        const { CheckoutSidebarContent } = await import(
            "./components/CheckoutSidebarContent"
        );
        const { FinancingUpsellBlock } = await import(
            "tsi-common-react/src/apps/checkout/containers/FinancingUpsellBlock"
        );
        return (
            <Provider store={store}>
                <Checkout
                    showFinancingPreQual={strToBool(
                        getPageSetting("show-pre-approval"),
                    )}
                    enableSplitPay={strToBool(
                        getPageSetting("enable-split-pay"),
                    )}
                    formSteps={[
                        // Disable the shipping method step
                        Step.LOGIN,
                        Step.SHIPPING_ADDRESS,
                        Step.SHIPPING_METHOD,
                        Step.BILLING_ADDRESS,
                        Step.PAYMENT_METHODS,
                        Step.PLACING_ORDER,
                    ]}
                    getExtraSummaryContent={() => {
                        return null;
                    }}
                    getExtraSidebarContent={() => {
                        return <CheckoutSidebarContent />;
                    }}
                    buildFinancingUpsell={(grandTotal) => {
                        return <FinancingUpsellBlock grandTotal={grandTotal} />;
                    }}
                />
            </Provider>
        );
    }).catch(console.error);

    // Render Message Banner
    dynamicPlaceComponent(
        '[data-place-react="message-banner"]',
        async (elem) => {
            const { MessageBanner } = await import(
                "tsi-common-react/src/apps/checkout/containers/MessageBanner"
            );
            const tags = elem.dataset.tags || "";
            const message = elem.dataset.message || "";
            return (
                <Provider store={store}>
                    <MessageBanner tags={tags} message={message} />
                </Provider>
            );
        },
    ).catch(console.error);

    // Render Financing Form Application
    dynamicPlaceComponent(
        '[data-place-react="financing-form"]',
        async (elem) => {
            const { FinancingModalTrigger } = await import(
                "tsi-common-react/src/apps/financing/FinancingModalTrigger"
            );
            const linkClass =
                elem.dataset.linkClass ||
                "financing__offer__cta--apply al-financing-page__apply-now-button";
            const linkTitle = elem.dataset.linkTitle || "Apply Now";
            const linkText = elem.dataset.linkText || "Apply Now";
            const applicationSource =
                elem.dataset.applicationSource || "Financing Page";
            return (
                <Provider store={store}>
                    <FinancingModalTrigger
                        modalType="full-app"
                        className={linkClass}
                        title={linkTitle}
                        applicationSource={applicationSource}
                    >
                        {linkText}
                    </FinancingModalTrigger>
                </Provider>
            );
        },
    ).catch(console.error);

    // Render Pre Approval Trigger for Finance Page
    dynamicPlaceComponent(
        '[data-place-react="financing-modal-trigger--finance-page"]',
        async () => {
            const { FinancingModalTrigger } = await import(
                "tsi-common-react/src/apps/financing/FinancingModalTrigger"
            );
            return (
                <Provider store={store}>
                    <FinancingModalTrigger modalType="prequal-app">
                        Check Now
                    </FinancingModalTrigger>
                </Provider>
            );
        },
    ).catch(console.error);

    // Render Pre Approval Trigger for Homepage
    dynamicPlaceComponent(
        '[data-place-react="financing-modal-trigger--block-home"]',
        async () => {
            const { FinancingModalTriggerBlock } = await import(
                "./components/FinancingModalTriggerBlock"
            );
            return (
                <Provider store={store}>
                    <FinancingModalTriggerBlock />
                </Provider>
            );
        },
    ).catch(console.error);

    // Render Pre Approval Triggers
    dynamicPlaceComponent(
        '[data-place-react="financing-modal-trigger--block"]',
        async (elem) => {
            const { FinancingModalTriggerBlock } = await import(
                "tsi-common-react/src/apps/financing/FinancingModalTriggerBlock"
            );
            return (
                <Provider store={store}>
                    <FinancingModalTriggerBlock
                        cardType={FinancingCardType.COCOON_CC}
                        theme={
                            (elem.dataset
                                .theme as FinancingModalTriggerTheme) ||
                            FinancingModalTriggerTheme.DEFAULT
                        }
                        aprInfoID={"#apr-information"}
                        financeLink={urls.pageURL("finance-link")}
                    />
                </Provider>
            );
        },
    ).catch(console.error);

    // Render Financing Modal (this is what opens in response to the
    // FinancingModalTriggers scattered across the site.)
    dynamicPlaceComponent('[data-place-react="financing-modal"]', async () => {
        const { FinancingModal } = await import(
            "tsi-common-react/src/apps/financing/FinancingModal"
        );
        return (
            <Provider store={store}>
                <FinancingModal />
            </Provider>
        );
    }).catch(console.error);

    // Render Offer images
    dynamicPlaceComponent('[data-place-react="order-offer"]', async (elem) => {
        const desktop_image = elem.dataset.desktop_image || "";
        const mobile_image = elem.dataset.mobile_image || "";
        const offer_freq = elem.dataset.offer_freq || "";
        const benefit_value = elem.dataset.benefit_value || "";
        const offer_name = elem.dataset.offer_name || "";

        const { OrderOfferImage } = await import(
            "./components/OrderOfferImage"
        );
        const { OrderOffer } = await import("./components/OrderOffer");
        if (!!offer_freq && !!benefit_value) {
            return (
                <Provider store={store}>
                    <OrderOffer
                        offer_freq={offer_freq}
                        benefit_value={benefit_value}
                        desktop_image={desktop_image}
                        mobile_image={mobile_image}
                        offer_name={offer_name}
                    />
                </Provider>
            );
        }
        if (!!desktop_image && !!mobile_image) {
            return (
                <Provider store={store}>
                    <OrderOfferImage
                        desktop_image={desktop_image}
                        mobile_image={mobile_image}
                    />
                </Provider>
            );
        }
        return null;
    }).catch(console.error);
});

// ============================================================================
// Special On-Page-Load Functionality
// ============================================================================
const wipeCheckoutData = () => {
    console.debug("Erasing transient checkout data...");
    persistor.purge();
    checkoutAPI.clearShippingAddress();
    checkoutAPI.clearBillingAddress();
    loaders.resetFormData();
};

onReadyStateComplete.on(async () => {
    const url = new URL(window.location.href);

    // Wait for redux store to finish loading
    await rehydratingStore;
    await componentsRendering;

    // Redirect from basket page to homepage after 60 min delay
    const basketApp = document.getElementById("basket-app");
    if (basketApp) {
        setTimeout(() => {
            urls.navigateToHome();
        }, 3600_000);
    }

    // Auto open PreQual modal?
    const openPreQualModel = strToBool(
        `${url.searchParams.get("resume-prequal") || "no"}`,
    );
    if (openPreQualModel) {
        store.dispatch(openPreQualModal({}));
    }

    // Auto open Financing Application modal?
    const openCreditAppModel = strToBool(
        `${url.searchParams.get("open-credit-app") || "no"}`,
    );
    if (openCreditAppModel) {
        const appSource = `${url.searchParams.get("credit-app-source")}`;
        store.dispatch(
            openApplyModal({
                applicationSource: appSource || "Financing Page",
            }),
        );
    }

    // Wipe checkout data?
    if (document.body.classList.contains("js-wipe-checkout-data")) {
        wipeCheckoutData();
    }
});

// ============================================================================
// CSR Toolbar Integration
// ============================================================================
const reloadCheckoutData = () => {
    wipeCheckoutData();
    console.log("Reloading basket data");
    loaders.loadBasket();
    console.log("Reloading shipping and billing address");
    loaders.loadShippingAndBillingAddresses();
};

signals.csr.onBeforeStartNewBasket.on(wipeCheckoutData);
signals.csr.onAfterStartAssistingCustomer.on(reloadCheckoutData);
signals.csr.onAfterStopAssistingCustomer.on(reloadCheckoutData);
