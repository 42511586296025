import React from "react";
import { Provider } from "react-redux";
import {
    isoImageURL,
    isoProductID,
} from "tsi-common-react/src/models/nominals";
import { check } from "tsi-common-react/src/models/utils";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { urls } from "tsi-common-react/src/utils/urls";

import { rehydratingStore, store } from "../store";
import { ProductPage } from "./models";

/**
 * Place PDPHero components
 */
dynamicPlaceComponent('[data-place-react="pdp-hero"]', async () => {
    const { PDPHero } = await import(
        "tsi-common-react/src/apps/configurator/containers/PDPHero"
    );
    const detailContainerElem = document.querySelector<HTMLDivElement>(
        "#product-detail-container[data-cms]",
    );
    const cmsData = check(
        ProductPage.decode(
            JSON.parse(detailContainerElem?.dataset.cms || "{}"),
        ),
    );
    return (
        <Provider store={store}>
            <PDPHero
                defaultBackgroundImage={cmsData.hero_image_url}
                defaultMobileBackgroundImage={cmsData.mobile_hero_image_url}
                defaultImageTitle={cmsData.hero_image_alt || ""}
                variantHeroInfo={cmsData.variant_hero_images_with_urls}
            />
        </Provider>
    );
}).catch(console.error);

/**
 * Place Legacy Product Hero components
 */
dynamicPlaceComponent(
    '[data-place-react="legacy-product-hero"]',
    async (elem) => {
        const { PDPHero } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPHero"
        );
        const cmsData = JSON.parse(elem.dataset.cms || "{}");
        return (
            <Provider store={store}>
                <PDPHero
                    defaultBackgroundImage={cmsData.hero_image_url}
                    defaultMobileBackgroundImage={cmsData.mobile_hero_image_url}
                    defaultImageTitle={cmsData.hero_image_alt || ""}
                />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Render Configurators
 */
dynamicPlaceComponent(
    '[data-place-react="modular-configurator"]',
    async (elem) => {
        const { ModularConfigurator } = await import(
            "tsi-common-react/src/apps/configurator/containers/ModularConfigurator"
        );
        const { UpsellModal } = await import("./components/UpsellModal");
        const phoneNumber = elem.dataset.phoneNumber;
        await readyStateComplete;
        await rehydratingStore;
        return (
            <Provider store={store}>
                <ModularConfigurator
                    optionSetJSON={elem.dataset.configuratorInitData || ""}
                    passthroughProps={{
                        title: elem.dataset.title,
                        preheader: "",
                        subhead: "",
                        description: elem.dataset.description,
                        descriptions: [],
                        enableHistoryInteraction: true,
                        showUpsellModal: () => {
                            return !!elem.dataset.upsellModal;
                        },
                        getUpsellModalComponentClass: () => {
                            return UpsellModal;
                        },
                        buttonColor: "lochinvar",
                        financingLink: urls.pageURL("finance-link-apr"),
                        showStarRatingsModal: true,
                        configType: "pdp-hero",
                        showImagePerUpgradeOption: true,
                        hideLearnMoreLink: true,
                        showUpsellModalIcon: true,
                        phoneNumber: phoneNumber,
                    }}
                />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Render Modal Tiggers
 */
dynamicPlaceComponent(
    '[data-place-react="financing-modal-trigger"]',
    async () => {
        const { FinancingModalTriggerConfigurator } = await import(
            "tsi-common-react/src/apps/financing/FinancingModalTrigger"
        );
        return (
            <Provider store={store}>
                <FinancingModalTriggerConfigurator />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Render Galleries
 */
dynamicPlaceComponent('[data-place-react="pdp-gallery"]', async () => {
    const { Gallery } = await import("./containers/PDPGallery");
    await readyStateComplete;
    return (
        <Provider store={store}>
            <Gallery />
        </Provider>
    );
}).catch(console.error);

/**
 * Render GalleryBlock
 */
dynamicPlaceComponent('[data-place-react="gallery-block"]', async (elem) => {
    const { GalleryBlock } = await import(
        "tsi-common-react/src/apps/gallery/components/GalleryBlock"
    );
    return (
        <Provider store={store}>
            <GalleryBlock
                galleriesJSON={elem.dataset.galleries || "{}"}
                defaultThumbnail={isoImageURL.wrap(
                    elem.dataset.videoThumbnail || "",
                )}
            />
        </Provider>
    );
}).catch(console.error);

/**
 * Render SelectedRootProductConditionalBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-selected-root-product-conditional-block"]',
    async (elem) => {
        const { PDPSelectedRootProductConditionalBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPSelectedRootProductConditionalBlock"
        );
        const productID = isoProductID.wrap(
            parseInt(elem.dataset.product || "0", 10),
        );
        const htmlContent = elem.innerHTML || "";
        // Show the root element
        elem.style.display = "block";
        return (
            <Provider store={store}>
                <PDPSelectedRootProductConditionalBlock
                    blockProductID={productID}
                    content={htmlContent}
                />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Render PDPChangeRootProductBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-change-root-product-block"]',
    async () => {
        const { PDPChangeRootProductBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPChangeRootProductBlock"
        );
        return (
            <Provider store={store}>
                <PDPChangeRootProductBlock />
            </Provider>
        );
    },
).catch(console.error);

/**
 * Render EmailCaptureBlock
 */
dynamicPlaceComponent(
    '[data-place-react="email-capture-block"]',
    async (elem) => {
        const { EmailCaptureContent } = await import(
            "tsi-common-react/src/apps/common/containers/EmailCaptureContent"
        );
        const cms_data = JSON.parse(elem.dataset.cms || "null");

        return <EmailCaptureContent content={cms_data} />;
    },
).catch(console.error);

/**
 * Render compatibility module
 */
dynamicPlaceComponent('[data-place-react="compatibility"]', async (elem) => {
    const { CompatibilityAccordion } = await import(
        "tsi-common-react/src/blocks/components/CompatibilityAccordion"
    );
    const main = JSON.parse(elem.dataset.cms || "");

    return (
        <CompatibilityAccordion
            cms={main}
            contentPanels={main.compatibility_panels.panels}
        />
    );
}).catch(console.error);
