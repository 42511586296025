import React from "react";
import { snapUserDataLayerState } from "tsi-common-react/src/api/datalayer";
import { registerCascades } from "tsi-common-react/src/apps/common/cascades";
import { Dispatchers } from "tsi-common-react/src/apps/common/dispatchers";
import { Loaders } from "tsi-common-react/src/apps/common/loaders";
import { initCSRToolbar } from "tsi-common-react/src/apps/csr";
import { isoWebPageURL } from "tsi-common-react/src/models/nominals";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { urls } from "tsi-common-react/src/utils/urls";

import { rehydratingStore, store } from "../store";

export const dispatchers = new Dispatchers(store.dispatch);
export const loaders = new Loaders(dispatchers);

// Register store cascades
registerCascades(store);

// Render Session Keep-Alive Modal
dynamicPlaceComponent(
    '[data-place-react="session-keep-alive-modal"]',
    async () => {
        const { SessionKeepAlive } = await import(
            "tsi-common-react/src/apps/authentication/components/SessionKeepAlive"
        );
        return <SessionKeepAlive />;
    },
).catch(console.error);

// Render countdown timer
dynamicPlaceComponent('[data-place-react="countdown-timer"]', async (elem) => {
    const { CountdownTimer } = await import(
        "tsi-common-react/src/apps/countdown-timer/container/CountdownTimer"
    );
    const timerData = JSON.parse(elem.dataset.timers || "");
    const timersExpiredPromo = elem.dataset.promo || "";
    return (
        <CountdownTimer
            timerData={timerData}
            timersExpiredPromo={timersExpiredPromo}
        />
    );
}).catch(console.error);

// Place Live Chat Links
dynamicPlaceComponent('[data-place-react="chat-link"]', async (elem) => {
    const { ChatLink } = await import(
        "tsi-common-react/src/apps/chat/ChatLink"
    );
    return (
        <ChatLink
            className={elem.dataset.chatLinkClass}
            chatOnlineText={elem.dataset.chatOnlineText}
            chatOfflineText={elem.dataset.chatOfflineText}
            chatOfflineLink={
                elem.dataset.chatOfflineLink
                    ? isoWebPageURL.wrap(elem.dataset.chatOfflineLink)
                    : urls.pageURL("support-link")
            }
            hideIfOffline={JSON.parse(elem.dataset.hideIfOffline || "false")}
        />
    );
}).catch(console.error);

// Render Value Props bar
dynamicPlaceComponent('[data-place-react="value-props-bar"]', async (elem) => {
    const { ValuePropsBar } = await import(
        "tsi-common-react/src/common/ValuePropsBar"
    );
    const parsedData = JSON.parse(elem.dataset.cms || "");
    return <ValuePropsBar valueProps={parsedData.value_props} />;
}).catch(console.error);

// Render Sticky email banner
dynamicPlaceComponent(
    '[data-place-react="email-signup-banner"]',
    async (elem) => {
        const { StickyEmailBanner } = await import(
            "tsi-common-react/src/common/StickyEmailBanner"
        );
        const bannerData = JSON.parse(elem.dataset.banner || "");
        return <StickyEmailBanner bannerData={bannerData} />;
    },
).catch(console.error);

// Render user data layer element
dynamicPlaceComponent('[data-place-react="user-data-layer"]', async (elem) => {
    await readyStateComplete;
    // Pause for 1 second to allow the data layer push scripts to run after readyState is complete
    await (async () => {
        return new Promise((res) => {
            setTimeout(res, 1000);
        });
    })();
    const orderID = elem.dataset.order || "";
    await snapUserDataLayerState(orderID);
    return null;
}).catch(console.error);

// Render the CSR toolbar
initCSRToolbar({
    rehydratingStore,
    loaders,
    store,
}).catch(console.error);
