import React from "react";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";

// Render Offer Modals
dynamicPlaceComponent('[data-place-react="offer-modal"]', async (elem) => {
    const { OfferModal } = await import(
        "tsi-common-react/src/blocks/containers/OfferModal"
    );
    const url = elem.dataset.url || "";
    const cta = elem.dataset.cta || "";
    const arrow = elem.hasAttribute("data-arrow");
    return <OfferModal offerDetailURL={url} ctaText={cta} ctaArrow={arrow} />;
}).catch(console.error);
