import React from "react";
import { CartReferenceNumber } from "tsi-common-react/src/apps/checkout/CartReferenceNumber";
import { jt, t } from "ttag";

import { templates } from "@reactivated";

import logoNoStich from "../../static/img/global/cocoon-logo-without-stitch.svg";
import logo from "../../static/img/global/cocoon-logo.svg";
import iconQuestionMarkCircle from "../src/svg/question-mark-circle.svg";

const Logo = (props: { linkURL: string }) => (
    <a className="logo" href={props.linkURL} title={t`Cocoon By Sealy`}>
        <picture>
            <img
                className="header-brand-logo"
                src={logo}
                alt={t`Cocoon By Sealy`}
            />
            <img
                className="header-brand-logo--mobile"
                src={logoNoStich}
                alt={t`Cocoon By Sealy`}
            />
        </picture>
    </a>
);

export const Template = (
    props: templates.CheckoutHeaderTemplate,
): React.ReactNode => {
    const secure = (
        <span key="secure" className="checkout-header__title--bold">
            {t`SECURE`}
        </span>
    );
    const secureCheckout = jt`${secure} CHECKOUT`;
    return (
        <header>
            <nav className="checkout-header">
                <div className="main-container">
                    <div className="checkout-header__container">
                        <a
                            className="checkout-header__screen-reader-skip"
                            href="#main-content"
                        >
                            {t`Skip to main content`}
                        </a>
                        <Logo linkURL={props.root_page_url} />
                        <div className="checkout-header__title">
                            {secureCheckout}
                        </div>
                    </div>
                    <div className="checkout-header__container-right">
                        <div className="checkout-header__header-cart-ref">
                            <CartReferenceNumber
                                encodedBasketID={props.encoded_basket_id}
                                phoneNumber={props.support_website_phone_number}
                                helpIcon={iconQuestionMarkCircle}
                            />
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};
